<template>
    <div class="evaluate-window-box">
        <div class="evaluate-window">
            <h1 class="evaluate-window-head">
                <span></span>
                <span>{{ currentData.name }}</span>
                <span class="iconfont" @click="closeWindow">&#xe67d;</span>
            </h1>
            <div class="evaluation-list">
                <component
                    :is="currentView"
                    :add-table-list="addTableList"
                    :current-data="currentData"
                    :run-time-date="runTimeDate"
                    :view-only="viewOnly"
                    @closeWindow="closeWindow"
                    @saveList="saveList"
                ></component>
            </div>
        </div>
    </div>
</template>

<script>
import contract from './contractPayment'; // 合同回款
import supplier from './supplierQuantity'; // 供货方量
import prize from './sellingPrice'; // 销售价格
import debt from './debtReceivable'; // 应收欠款账龄
import service from './serviceEvaluation'; // 服务过程评价
import other from './other'; // 其他
export default {
    data() {
        return {
            currentView: '',
            addTableList: 0,
            saveData: [],
        };
    },
    props: {
        // 当前点击数据
        currentData: {
            type: Object,
        },
        // 当前期号
        runTimeDate: {
            type: Object,
        },
        // 是否只读
        viewOnly: {
            type: [String, Boolean],
        },
    },
    components: {
        contract, // 合同回款
        supplier, // 供货方量
        prize, // 销售价格
        debt, // 应收欠款账龄
        service, // 服务过程评价
        other, // 其他
    },
    mounted() {
        this.getWindowName();
    },
    methods: {
        saveList(data, Star) {
            const leng = data[data.length - 1];
            if (Star === 'Star') {
                this.saveData = data;
                this.saveData.forEach((item, index) => {
                    item.sort_num = index + 1;
                });
                this.addCreditScore();
            } else if (data.length === 0) {
                this.$message.warning('请添加数据');
            } else if (!leng.upper && leng.type !== '1' && leng.type !== '2') {
                this.$message.warning('请选择上限');
            } else if (!leng.floor && leng.type !== '4' && leng.type !== '5') {
                this.$message.warning('请选择下限');
            } else if (!leng.score) {
                this.$message.warning('请打分');
            } else {
                this.saveData = data;
                this.saveData.forEach((item, index) => {
                    item.sort_num = index + 1;
                });
                this.addCreditScore();
            }
        },
        // 获取点击列表
        getWindowName() {
            switch (this.currentData.code) {
                case '1':
                    this.currentView = 'contract';
                    break;
                case '2':
                    this.currentView = 'supplier';
                    break;
                case '3':
                    this.currentView = 'prize';
                    break;
                case '4':
                    this.currentView = 'debt';
                    break;
                case '5':
                    this.currentView = 'service';
                    break;
                case '6':
                    this.currentView = 'other';
                    break;
                default:
                    this.currentView = 'contract';
            }
        },
        // 关闭弹窗
        closeWindow() {
            this.$emit('closeWindow');
        },
        // 保存已配置项
        addCreditScore() {
            this.$axios
                .post('/interfaceApi/sale/credit_config/add_credit_score', this.saveData)
                .then(() => {
                    this.$message.success('保存成功');
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
    },
};

</script>
<style lang='stylus'>
.evaluate-window-box
    position fixed
    top 0
    left 0
    right 0
    bottom 0
    background rgba(0,0,0,0.4)
    display flex
    flex-direction column
    align-items center
    justify-content center
    z-index 2
    .evaluate-window
        width 90%
        min-height 7rem
        background #fff
        display flex
        flex-direction column
        .evaluate-window-head
            width 100%
            background #0C2C87
            text-align center
            color #fff
            height .6rem
            display flex
            justify-content space-between
            align-items center
            span
                font-size .2rem
                margin-right .2rem
                cursor pointer
                &:nth-child(2)
                    margin-left .3rem
        .evaluation-list
            height calc(100% - .6rem)
</style>